import AddressDTO from "@/dto/AddressDTO";

export default class CreateReserveDocument {
    companyId: number | null = null
    address: AddressDTO = new AddressDTO()
    initialAmount: string | null = null
    minBalance: string | null = null
    replenishPeriod: string | null = null
    terminationNoticePeriod: string | null = null;
    returnPeriod: number | null = null
    officerName: string | null = null
    officerTitle: string | null = null
}