


























































































































































































import {Component, Vue} from "vue-property-decorator";
import {namespace} from "vuex-class";
import CompanyService from "@/services/CompanyService";
import ErrorLocalePathUtils from "@/utils/ErrorLocalePathUtils";
import CompanyDetailsDTO from "@/dto/company/CompanyDetailsDTO";
import FileMetaDTO from "@/dto/files/FileMetaDTO";
import UpdateCompanyDetailsRequest from "@/dto/company/UpdateCompanyDetailsRequest";
import FileUpload from "@/components/common/FileUpload.vue";
import {documentAccept} from "@/constants/FileConstants";
import PhoneInput from "@/components/util/PhoneInput.vue";
import UserDTO from "@/dto/UserDTO";
import NAICS_DICT from "@/data/NaicsDict.json";
import NaicsType from "@/dto/NaicsType";
import Workspaces from "@/state/Workspaces";
import AutoComplete from "@/components/common/AutoComplete.vue";
import AutoCompleteDTO from "@/components/common/AutoCompleteDTO";
import RegexUtils from "@/utils/RegexUtils";
import PortalInput from "@/components/common/PortalInput.vue";
import {validate} from "@/utils/ComponentUtils";
import SingleFileHolder from "@/components/util/SingleFileHolder.vue";
import {InputType} from "@/components/common/InputType";
import PortalSelect from "@/components/common/PortalSelect.vue";
import SelectOption from "@/components/common/SelectOption";
import {FederalTaxClassification} from "@/constants/FederalTaxClassification";
import BTPortalCollapse from "@/components/common/BTPortalCollapse.vue";
import {IEmployment} from "@/dto/payroll/IEmployment";
import RouteNames from "@/router/RouteNames";
import EmployeeService from "@/services/EmploymentService";
import ReserveAgreementCreationModal from "@/components/profile/company/ReserveAgreementCreationModal.vue";

const AppModule = namespace("App");
const Auth = namespace("Auth");

@Component({
	methods: {
		RouteNames() {
			return RouteNames
		}
	},
	computed: {
		SelectOption() {
			return SelectOption
		},
		InputType() {
			return InputType
		},
		naics(): Array<NaicsType> {
			return NAICS_DICT;
		},
		AutoCompleteDTO() {
			return AutoCompleteDTO
		}
	},
    components: {
		BTPortalCollapse,
		PortalSelect,
		SingleFileHolder,
		PortalInput,
		AutoComplete,
        PhoneInput,
        FileUpload
    }
})
export default class CompanyDetails extends Vue {
    private accept = documentAccept
    private insertNaicsType: string | null = null;
    private naicsTypes: Array<NaicsType> = NAICS_DICT;
	private employments: Array<IEmployment> = [];

	@Auth.Getter("hasAdministrativePrivileges")
	private hasAdministrativePrivileges!: boolean;

	private companyDetailsIsOpen = true;

    @AppModule.State
    private isMobile!: boolean;

    @AppModule.Action
    private startLoading!: () => void;

    @AppModule.Action
    private stopLoading!: () => void;

    @Auth.State
    private user!: UserDTO

    private message = "";

    private successful = false;

	private isUserSublimeEmployee: boolean = Workspaces.isUserSublimeEmployee;

    private company: CompanyDetailsDTO = new CompanyDetailsDTO();

    private cerOfIncMeta: FileMetaDTO | null = null;

    private fillingReceiptMeta: FileMetaDTO | null = null;

    private ss4FormMeta: FileMetaDTO | null = null;

    private isDataReady = false;

	private buildClassificationOptions(): Array<SelectOption> {
		return [
			SelectOption.builder().title("Single member LLC").value(FederalTaxClassification.SOLE).build(),
			SelectOption.builder().title("C Corporation").value(FederalTaxClassification.C_CORP).build(),
			SelectOption.builder().title("S Corporation").value(FederalTaxClassification.S_CORP).build(),
			SelectOption.builder().title("Partnership").value(FederalTaxClassification.PARTNERSHIP).build(),
			SelectOption.builder().title("Trust/estate").value(FederalTaxClassification.TRUST_ESTATE).build(),
			SelectOption.builder().title("Limited liability company").value(FederalTaxClassification.LLC).build(),
			SelectOption.builder().title("Other").value(FederalTaxClassification.OTHER).build()
		];
	}

    mounted() {
      Promise.all([this.loadCompanyDetails(), this.loadEmployments()]).then(
		  res => {
			  this.successful = true;
			  this.message = "";
			  this.stopLoading();
		  },
		  err => this.errorHandle(err)
	  )
    }

	private loadEmployments(): Promise<void> {
		this.message = "";
		this.successful = false;
		return EmployeeService.getAllEmploymentsByDetailsId().then(
			res => {
				if (res.data) {
					this.employments.push(...res.data)
				}
			},
			err => {
				this.errorHandle(err)
			}
		)
	}

    errorHandle(error: any) {
        this.successful = false;
        const param = error?.response?.data?.param ? { param: error?.response?.data?.param } : {}
        this.message = this.$t(ErrorLocalePathUtils.getPathForError(error), param) as string;
        this.stopLoading();
    }

    loadCompanyDetails(): Promise<void> {
        this.message = "";
        this.successful = false;
        this.startLoading();
        return CompanyService.getDetailsById(Workspaces.getCurrent.id).then(
            res => {
                this.isDataReady = true;
                this.company = res.data;
                this.successful = true;
                this.stopLoading();
            },
            err => {
                this.errorHandle(err)
            }
        )
    }

    onCertificateOfIncorporationLoaded(files: FileMetaDTO[]) {
        if (!files.length) {
            return;
        }
        this.cerOfIncMeta = files[0];
    }

    onFillingReceiptLoaded(files: FileMetaDTO[]) {
        if (!files.length) {
            return;
        }
        this.fillingReceiptMeta = files[0];
    }

    successHandle() {
        this.loadCompanyDetails();
        this.stopLoading();
        this.successful = true;
        this.message = this.$t("util.success.company_details") as string;
    }

  buildUpdateCompanyDetailsRequest(): UpdateCompanyDetailsRequest {
    let req = new UpdateCompanyDetailsRequest();
    req.companyId = Workspaces.getCurrent.id;
    req.name = this.company.name;
    req.ein = this.company.ein;
    req.dba = this.company.dba;
    req.website = this.company.website;
    req.federalTaxClass = this.company.federalTaxClass;
    req.phone = this.company.phone;
    req.naicsCode = this.company.naicsCode
    req.nationalTin = this.company.nationalTin;
    if (this.cerOfIncMeta) {
      req.cerOfInMetaId = this.cerOfIncMeta.id;
    }

    if (this.fillingReceiptMeta) {
      req.filingReceiptMetaId = this.fillingReceiptMeta.id;
    }

    req.booksProLink = this.company.booksProLink;
    return req;
  }

    private save(): void {
		validate(this).then(isValid => {
			if (isValid) {
				this.startLoading();
				CompanyService.updateCompanyDetails(this.buildUpdateCompanyDetailsRequest()).then(
					() => {this.successHandle()},
					(error) => {this.errorHandle(error)}
				).then(() => this.stopLoading());
			}
		})
    }

    handleHeight() {
        return (this.isMobile ? '100' : '300') + 'px';
    }

    handleLabelForCerOfInf() : string {
        return (!this.cerOfIncMeta ? "Upload " : "Change ") + "certificate of incorporation";
    }

    handleLabelForFillReceipt() : string {
        return (!this.fillingReceiptMeta ? "Upload " : "Change ") + "filling receipt";
    }

	private requestReserveAgreement(): void {
		this.$modal.show(
			ReserveAgreementCreationModal,
			{
				company: this.company,
				onSuccess: () => this.loadCompanyDetails()
			}
		)
	}

}
