import FileMetaDTO from "@/dto/files/FileMetaDTO";
import SignableDocumentDTO from "@/dto/files/SignableDocumentDTO";

export default class CompanyDetailsDTO {
    public id: number | null = null;

    public name: string | null = null;

    public ein: string | null = null;

    public dba: string | null = null;

    public nationalTin: string | null = null;

    public website: string | null = null;

    public phone: string | null = null;

    public federalTaxClass: string | null = null;

    public certificateOfIncorporation: FileMetaDTO | null = null;

    public filingReceipt: FileMetaDTO | null = null;

    public naicsCode: number | null = null;

    public reserveAgreement: SignableDocumentDTO | null = null;

    public booksProLink: string | null = null;

}